import { $, addAction, INIT } from '@situation/setdesign.util';

const handleGateDisplay = () => {
    const $container = $('.agenda-event-detail');
    const $gateContainer = $container.find('.agenda-event-detail__gate');
    if (!$gateContainer.length) {
        return;
    }

    const toggleGateDisplay = (showGate) => {
        $container
            .toggleClass('agenda-event-detail--gate-hide', !showGate)
            .toggleClass('agenda-event-detail--gate-show', showGate);

        window.scrollTo(0, 0);
    };

    const currentPath = window.location.pathname;
    const gatePassedKey = `auth${currentPath}`;
    const gatePassed = localStorage.getItem(gatePassedKey) === 'true';
    if (gatePassed) {
        toggleGateDisplay(false);
        return;
    }

    const $form = $gateContainer.find('.gform_wrapper > form');
    const formId = $form.data('formid');
    $(document).on('gform_confirmation_loaded', (e, loadedFormId) => {
        if (parseInt(loadedFormId, 10) === parseInt(formId, 10)) {
            localStorage.setItem(gatePassedKey, 'true');
            toggleGateDisplay(false);
        }
    });
};

addAction(INIT, handleGateDisplay);
