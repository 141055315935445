import { READY, addAction } from '@situation/setdesign.util';
import lax from 'lax.js';

const registerBackgroundAnimations = () => {
    const parallaxSection = document.querySelectorAll('.lax.lax--parallax');
    if (parallaxSection.length > 0) {
        lax.init();
        lax.addDriver('scrollY', () => window.scrollY);
        parallaxSection.forEach((section) => {
            const sectionImageContainer = section.querySelector('.content-background-image');
            if (sectionImageContainer) {
                lax.addElement(sectionImageContainer, {
                    scrollY: {
                        translateY: [
                            ['elInY', 'elOutY'],
                            { 480: [-50, -100], 768: [0, -150] },
                            { easing: 'easeInOutQuint' },
                        ],
                    },
                });
            }
        });
    }
};

addAction(READY, registerBackgroundAnimations);
