import { $, addAction, INIT } from '@situation/setdesign.util';

const removeEmpty = ($el) => {
    if (!$el.find('.content-column__inner').children().length) {
        $el.remove();
    }
};

const moveAgendaEvents = () => $('.agenda-events--tabs').each((_, el) => {
    const $el = $(el);
    const $row = $el.closest('.content-row');
    const $sec = $el.closest('.content-section').after($el);
    if ($sec.hasClass('content-section--mb-none')) {
        $el.addClass('agenda-events--mb-none');
    } else {
        $sec.addClass('content-section--mb-none');
    }

    removeEmpty($row);
    removeEmpty($sec);
});
addAction(INIT, moveAgendaEvents);
