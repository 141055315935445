import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

const initAction = () => {
    const $footerNav = $('.footer-nav');
    if ($footerNav.length) {
        addFilter('css-vars/register', (styles) => ({
            ...styles,
            'footer-height': () => `${$footerNav.outerHeight(true)}px`,
        }));
    }
};

addAction(INIT, initAction);
