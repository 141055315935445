/**
 * This module provides functionality for creating and managing various slider variations based on
 * modifiers to the `.content-slider` class. Use `createVariation` to configure new slider types.
 *
 * `createVariation('new-variation-name', { ...config });`
 */
import { addFilter } from '@situation/setdesign.util';

const icon = (name) => `<span class="icon icon-${name}"><svg><use class="icon-use" xlink:href="#icon-${name}"></use></svg></span>`;
const sliderVariations = [];
const createVariation = (modifier, options) => sliderVariations.push({ modifier, options });
const baseSettings = { margin: 32, navText: [icon('carousel-arrow'), icon('carousel-arrow')] };
createVariation('speakers', {
    items: 4,
    margin: 10,
    responsive: {
        0: { items: 1, stagePadding: 30 },
        480: { items: 2 },
        769: { items: 3, stagePadding: 0 },
        1080: { items: 4 },
    },
});

createVariation('mask', { nav: false });
createVariation('multimedia', { navText: [icon('arrow-right'), icon('arrow-right')] });
addFilter('content-slider/owl-settings', (options, $instance) => {
    const additionalOptions = sliderVariations.find((variation) => $instance.hasClass(`content-slider--${variation.modifier}`));
    return { ...options, ...baseSettings, ...(additionalOptions?.options ?? {}) };
});
