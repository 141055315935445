import { $, addAction, INIT } from '@situation/setdesign.util';
import dayjs from 'dayjs';
import getNow from '../util/now';
import selectedAudiences from '../util/selected-audiences';

const now = getNow();

const handleAgendaEventCard = () => {
    const audiences = selectedAudiences();
    const filterView = !(window.location.hash === '#full' || window.location.hash === '#nls');
    $('.agenda-event-card').each((i, el) => {
        const $card = $(el);
        const audienceIDs = ($card.data('audienceIds') || '').toString().split(',');
        const isRelevantAudience = audienceIDs.some((id) => audiences.includes(id));
        if (!isRelevantAudience && filterView) {
            $card.remove();
            return;
        }

        const startTimeStr = $card.data('startTime');
        const endTimeStr = $card.data('endTime');
        let newClass;
        if (startTimeStr && endTimeStr) {
            newClass = 'agenda-event-card--current';
            const startTime = dayjs(startTimeStr);
            const endTime = dayjs(endTimeStr);
            if (now.isBefore(startTime)) {
                newClass = 'agenda-event-card--future';
            } else if (now.isAfter(endTime)) {
                newClass = 'agenda-event-card--past';
            }
            if (now.isSame(startTime, 'day')) {
                newClass += ' agenda-event-card--today';
            }
        }

        $card
            .removeClass(
                'agenda-event-card--today agenda-event-card--current agenda-event-card--future agenda-event-card--past',
            )
            .addClass(newClass || '');
    });

    $('.agenda-events').each((_, container) => {
        const $container = $(container);
        if (filterView) {
            const uniqueHighlightColors = new Set(
                $container
                    .find('.agenda-event-card')
                    .map((i, card) => card.style.getPropertyValue('--highlight-color'))
                    .get()
                    .filter(Boolean),
            );
            $container
                .toggleClass('agenda-events--single-audience', uniqueHighlightColors.size === 1)
                .toggleClass('agenda-events--multi-audience', uniqueHighlightColors.size > 1);
        }
    });

    $('.agenda-events--upcoming-livestream').each((index, container) => {
        const $container = $(container);
        const $visibleCards = $container
            .find('.agenda-event-card')
            .filter((index, card) => $(card).css('display') !== 'none');
        $visibleCards.slice(5).remove();

        if ($visibleCards.length === 0) {
            $container.closest('.content-section').remove();
        }
    });
};

addAction(INIT, handleAgendaEventCard);
