import {
    _, $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $els = $('.count-animation');
    $els.html((i, html) => {
        const chars = $.trim(html.replace(/…/g, '...')).split('');
        return chars.map((char) => (char === ' ' ? char : `<span>${char}</span>`)).join('');
    })
        .addClass('show')
        .find('span')
        .each((index, el) => {
            const $el = $(el);
            $el.addClass($.isNumeric($el.text()) ? 'num' : 'char');
        });

    const showAnimation = _.debounce(
        () => {
            const delay = 150;
            // let delaySum = 0;
            $els.each((idx, eachEl) => {
                $(eachEl)
                    .find('span')
                    .each((index, el) => {
                        const $char = $(el);
                        // delaySum = delay * index;
                        setTimeout(() => {
                            $char.addClass('show');
                        }, delay * index);
                    });
            });
        },
        1000,
        { leading: false },
    );
    if ($els.length) {
        SetDesign.scrollWatch($els, showAnimation);
    }
});
