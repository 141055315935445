import { $ } from '@situation/setdesign.util';

const selectedAudiences = () => {
    const $agendaEvents = $('.agenda-events[data-audience-ids]');
    let audienceArray = [];
    if ($agendaEvents.length) {
        audienceArray = $agendaEvents.data('audienceIds').toString().split(',');
    } else {
        const storedAudience = localStorage.getItem('audience');
        if (storedAudience) {
            audienceArray = JSON.parse(storedAudience);
        } else {
            const defaultAudience = window.sit.default_audience || '';
            audienceArray = defaultAudience.toString().split(',');
        }
    }

    localStorage.setItem('audience', JSON.stringify(audienceArray));
    return audienceArray;
};

export default selectedAudiences;
