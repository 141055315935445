import {
    $, doAction, addAction, INIT, SET_HASH_STATE,
} from '@situation/setdesign.util';
import getNow from '../util/now';

const matchTabToCurrentDate = () => {
    const $tabs = $('.agenda-events__tabs .sub-nav__link');
    const hashLink = window.location.hash;
    if (!$tabs.length || hashLink) {
        return;
    }

    const now = getNow();
    const matchingID = now.format('MMM-D-YYYY').toLowerCase();
    const $matchingTab = $tabs.filter((_, tab) => $(tab).attr('href') === `#${matchingID}`);
    if ($matchingTab.length) {
        doAction(SET_HASH_STATE, matchingID);
    }
};

const scrollFooterLinksToTab = () => {
    $('.agenda-events__footer-nav-item').on('click', (e) => {
        const $el = $(e.target);
        const $target = $el.closest('.agenda-events');
        if ($target.length) {
            const headerHeight = $('.header-nav').outerHeight();
            const scrollPosition = $target.offset().top - headerHeight;
            $('html, body').animate({ scrollTop: scrollPosition }, 'fast');
        }
    });
};

addAction(INIT, () => {
    matchTabToCurrentDate();
    scrollFooterLinksToTab();
});
