import { $, addAction, INIT } from '@situation/setdesign.util';

const groupButtons = () => {
    $('.content-section, .footer-nav')
        .find('.button')
        .each((_, button) => {
            const $button = $(button);
            const $buttonWithSiblings = $button.siblings('.button').addBack();
            if ($buttonWithSiblings.length > 1 && !$button.parent().hasClass('button-group')) {
                $buttonWithSiblings.wrapAll('<div class="button-group col-reset-exclude"></div>');
            }
        });
};

addAction(INIT, groupButtons);
