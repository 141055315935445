import { $, addAction, INIT } from '@situation/setdesign.util';
import selectedAudiences from '../util/selected-audiences';

addAction(INIT, () => {
    const audiences = selectedAudiences();
    const defaultAudienceIds = (window.sit.default_audience || '').toString().split(',');
    const archiveTitles = window.sit.track_archive_titles || [];
    // Function to find a title by ID
    const findTitleById = (id) => archiveTitles.find((title) => title.id.toString() === id);
    // Find the default title based on the first default audience ID;
    const defaultTitle = findTitleById(defaultAudienceIds[0]);
    // Use array find method to replace the loop for finding the first non-default title
    const nonDefaultTitle = archiveTitles.find((title) => {
        const titleIdStr = title.id.toString();
        return (
            titleIdStr !== defaultAudienceIds[0]
            && audiences.some((audienceId) => titleIdStr === audienceId)
        );
    });
    let titleToDisplay = false;
    if (nonDefaultTitle) {
        titleToDisplay = nonDefaultTitle.title;
    } else if (defaultTitle) {
        titleToDisplay = defaultTitle.title;
    }
    // If a title to display is found, update the HTML content
    if (titleToDisplay) {
        $('[data-track-archive-title]').html(titleToDisplay);
    }
});
